import * as s from './ArLayout.module.scss'

import React, { useCallback, useEffect, useState } from 'react'

import { ArCookieBar } from 'src/components/ArCookieBar'
import { ArFooter } from 'src/components/ArFooter'
import { ArInternetExploderModal } from 'src/components/ArInternetExploderModal'
import { ArTopNav } from 'src/components/ArTopNav'
import { METRICS } from 'src/config/metrics'
import { ROUTES } from 'src/config/routes'
import { TArLayoutProps } from './ArLayout.types'
import { isPage } from 'src/utils/isPage'
import useDeviceDetect from 'src/utils/useDeviceDetect'
import { useLocation } from '@reach/router'

export const ArLayout: React.FC<TArLayoutProps> = ({ children, ...rest }) => {
  const { isIE } = useDeviceDetect()
  const [containerStyle, setContainerStyle] = useState<React.CSSProperties>()
  const [isHomepage, setIsHomepage] = useState<boolean>()
  const location = useLocation()

  const updateContainerStyle = useCallback(() => {
    const paddingTop = !isPage(ROUTES.homepage(), location.pathname);
    if (paddingTop) {
      setContainerStyle({ paddingTop: METRICS.TopNavHeight })
    } else {
      return setContainerStyle(undefined)
    }
  }, [location])

  useEffect(() => {
    setIsHomepage(isPage(ROUTES.homepage(), location.pathname))
    updateContainerStyle()
  }, [updateContainerStyle, location])

  return (
    <>
      <ArTopNav entryAnimation={isHomepage} />
      <div className={s.container} style={containerStyle}>
        {children}
      </div>
      <ArFooter />
      <ArCookieBar />
      {isIE && <ArInternetExploderModal />}
    </>
  )
}
