import * as s from './ArTopNav.module.scss'

import React, { useCallback, useContext, useEffect, useState } from 'react'

import { AppContext } from 'src/contexts/AppContext'
import { ArContainer } from 'src/components/ArContainer'
import ArtificialLogo from 'src/assets/svg/logo.svg'
import { Link } from 'gatsby'
import { METRICS } from 'src/config/metrics'
import { ROUTES } from 'src/config/routes'
import { TArTopNavProps } from './ArTopNav.types'
import classNames from 'classnames'
import { hasWindow } from 'src/utils/hasWindow'
import { isPage } from 'src/utils/isPage'
import useDeviceDetect from 'src/utils/useDeviceDetect'
import { useLocation } from '@reach/router'

const Menu: React.FC<{ layout: 'mobile' | 'desktop'; onItemClick?: () => void }> = ({ layout, onItemClick }) => {
  const { jobsData } = useContext(AppContext)

  const MENU_ITEMS = [
    { title: 'Insurers', to: ROUTES.platform() },
    { title: 'Brokers', to: ROUTES.brokers() },
    { title: 'About', to: ROUTES.about() },
    { title: 'Careers', to: ROUTES.careers(), notificationsCount: jobsData.length },
    { title: 'Blog', to: ROUTES.blog() },
    { title: 'Contact', to: ROUTES.contact() },
  ]

  const menuItemClass = (i: number) => {
    if (layout === 'desktop')
      return classNames({
        [s.desktopNav]: true,
        'pl-6': i < MENU_ITEMS.length,
        'pr-6': i < MENU_ITEMS.length - 1,
      })
  }

  return (
    <nav>
      <ul>
        {MENU_ITEMS.map(({ title, to, notificationsCount }, i) => {
          return (
            <li key={i} className={menuItemClass(i)}>
              <Link to={to} onClick={onItemClick} className={s.link}>
                <p data-notifs={notificationsCount}>{title}</p>
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export const ArTopNav: React.FC<TArTopNavProps> = ({ entryAnimation }) => {
  const { isSmallDevice } = useDeviceDetect()
  const location = useLocation()

  const { isMobileMenuOpen, setIsMobileMenuOpen } = useContext(AppContext)
  const [whiteBackground, setWhiteBackground] = useState(false)
  const [whiteBars, setWhiteBars] = useState(false)

  const toggleMenuOpen = () => setIsMobileMenuOpen(isSmallDevice ? !isMobileMenuOpen : false)

  const update = useCallback(() => {
    const isHomePage = isPage(ROUTES.homepage(), location.pathname)
    const stickedTop = hasWindow() && window.scrollY < 10
    setWhiteBackground(!stickedTop || !isHomePage)
    setWhiteBars(stickedTop && !isMobileMenuOpen && isHomePage)
  }, [isMobileMenuOpen, isSmallDevice, location.pathname])

  const containerClass = classNames(s.container, {
    [s.whiteBackground]: whiteBackground,
    [s.blackText]: whiteBackground || isMobileMenuOpen,
    [s.entryAnimation]: entryAnimation,
  })

  const buttonClass = classNames(s.closeButton, {
    [s.whiteBars]: whiteBars,
    [s.isMobileMenuOpen]: isMobileMenuOpen,
  })

  const mobileMenuWrapperClass = classNames(s.mobileMenuWrapper, {
    [s.isMobileMenuOpen]: isMobileMenuOpen,
  })

  useEffect(() => {
    update()
    document.addEventListener('scroll', update)
    return () => {
      document.removeEventListener('scroll', update)
    }
  }, [update])

  return (
    <div
      className={containerClass}
      style={{
        height: METRICS.TopNavHeight,
      }}>
      <ArContainer className={s.wrapper} style={{ height: METRICS.TopNavHeight }}>
        <Link to={ROUTES.homepage()}>
          <ArtificialLogo />
        </Link>
        <Menu layout="desktop" />
        <div className={mobileMenuWrapperClass}>
          <Menu
            layout="mobile"
            onItemClick={() => {
              setIsMobileMenuOpen(false)
            }}
          />
        </div>
        <button type="button" title="Menu" className={buttonClass} onClick={toggleMenuOpen}>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </ArContainer>
    </div>
  )
}
