export const METRICS = {
  TopNavHeight: 66,
  TopNavProductsMenuHeight: 50,
  HomepageHeroHeightMobile: 717,
  HomepageHeroHeightDesktop: 818,
  PlatformHeroHeightMobile: 460,
  PlatformHeroHeightDesktop: 587,
  FooterHeightMobile: 476,
  FooterHeightDesktop: 505,
}
